<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumbRoadside"></div>
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item :label="$t('searchModule.Merchant_Name')">
            <el-select
              v-model.trim="formInline.operationId"
              filterable
              size="15"
              placeholder="请选择"
              clearable
            >
              <!-- <el-option label="全部" value=""></el-option> -->
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleListName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.account_number')">
            <el-input v-model.trim="formInline.account" placeholder="账号" size="12"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.full_name')">
            <el-input v-model.trim="formInline.msUserName" placeholder="姓名" size="12"></el-input>
          </el-form-item>

          <el-form-item :label="$t('searchModule.role')">
            <el-select v-model.trim="formInline.role">
              <el-option
                :label="data.name"
                :value="data.code"
                v-for="data in roleList"
                :key="data.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Manage_the_parking_lot')">
            <el-autocomplete
              class="inline-input"
              v-model="modelvalue"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              value-key="parkName"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            v-if="$route.meta.authority.button.query"
            @click="
              page = 1;
              searchData();
            "
            :loading="loading"
            >{{ $t('button.search') }}</el-button
          >
          <router-link :to="{ path: '/PDAManageAdd' }" style="margin-left: 10px">
            <el-button type="primary" icon="el-icon-plus" v-if="$route.meta.authority.button.add"
              >添加
            </el-button>
          </router-link>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="90"
            v-if="$route.meta.authority.button.edit || $route.meta.authority.button.quit"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="$route.meta.authority.button.edit"
                    >编辑
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-show="scope.row.msUserState == 1"
                    v-if="$route.meta.authority.button.quit"
                    command="b"
                    >离职</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-show="scope.row.msUserState == 2"
                    v-if="$route.meta.authority.button.quit"
                    command="b"
                    >在职</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "collectorM",
  data() {
    return {
      roleListName: [],
      temParkData: {},
      modelvalue: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "",
        },
        {
          prop: "deptName",
          label: this.$t("list.Department_to"),
          width: "",
        },
        {
          prop: "msUserName",
          label: this.$t("list.full_name"),
          width: "80",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "130",
        },
        {
          prop: "account",
          label: this.$t("list.account_number"),
          width: "",
        },
        {
          prop: "role",
          label: this.$t("list.role"),
          width: "",
          formatter: (row) => {
            if (row.role == 0) {
              return "岗亭收费员";
            } else if (row.role == 1) {
              return "岗亭运维员";
            } else if (row.role == 2) {
              return "PDA收费员";
            }
          },
        },
        {
          prop: "parkNames",
          label: this.$t("list.Manage_the_parking_lot"),
          width: "150",
        },
        {
          prop: "createdTime",
          label: this.$t("list.add_time"),
          width: "",
        },
        {
          prop: "msUserState",
          label: this.$t("list.On_duty_status"),
          width: "100",
          formatter: (row) => {
            if (row.msUserState == 1) {
              return "在职";
            } else if (row.msUserState == 2) {
              return "离职";
            }
          },
        },
      ],
      tableData: [],
      roleList: [
        {
          code: 2,
          name: "PDA收费员",
        },
      ],
      formInline: {
        account: "",
        msUserName: "",
        role: 2,
        parkId: "",
        operationId: "",
      },
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
      }
    },
  },
  methods: {
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleListName = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getRole() {
      // 角色:0岗亭收费员1岗亭运维员
      this.roleList = [
        {
          coed: "0",
          name: "岗亭收费员",
        },
        {
          coed: "1",
          name: "岗亭运维员",
        },
      ];
      // this.$axios.get("/acb/2.0/pdaManager/spinner").then((res) => {
      //   if (res.state == 0) {
      //     this.roleList = res.value;
      //   } else {
      //     this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //       confirmButtonText: this.$t('pop_up.Determine'),
      //     });
      //   }
      // });
    },

    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 20,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.length
              ? res.value[0]
              : {
                  parkName: "",
                  parkId: "",
                };
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    handleCommand(cmd, data) {
      let obj = {
        ...data,
      };
      delete obj.parkNames;
      delete obj.parkIds;
      if (cmd == "a") {
        this.$router.push({
          path: "/PDAManageAdd",
          query: obj,
        });
      }
      if (cmd == "b") {
        this.$confirm("此操作将改变在职状态, 是否继续?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.changeState(data.msUserId, data.msUserState);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    changeState(id, state) {
      state = state == 1 ? "2" : "1";
      let url = "/acb/2.0/bacb/msUser/updateJobState";
      this.$axios
        .post(url, {
          data: {
            msUserId: id,
            msUserState: state,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            this.searchData();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 搜索
    searchData() {
      let url = "/acb/2.0/bacb/msUser/list";
      this.$axios
        .get(url, {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  activated() {
    this.searchData();
  },
  components: {},
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  created() {
    // this.getRole();//角色接口
    // this.searchData();
    this.getRoleList();
  },

  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumbRoadside {
  // height: 35px;
}

.content {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
<style scoped>
.el-button {
  margin: 0 !important;
}
</style>
